import { createWebHistory, createRouter } from "vue-router";
import Home from "./components/Home.vue";
import Login from "./components/Login.vue";
import Register from "./components/Register.vue";
import auth from './middlewares/auth';

// lazy-loaded
const ActivityList = () => import("./components/activity/List.vue")
const ActivityEdit = () => import("./components/activity/Edit.vue")
const ActivityNew = () => import("./components/activity/New.vue")
const ActivityDayList = () => import("./components/activity/DayList.vue")
const ActivityDetails = () => import("./components/activity/Details.vue")
const ActivitiesAvailable = () => import("./components/activity/ActivitiesAvailable.vue")
const LocationsAvailable = () => import("./components/reservation/LocationsAvailable.vue")
const ReservationNew = () => import("./components/reservation/New.vue")
const Card = () => import("./components/Card.vue")

const Forbidden = () => import("./components/pages/Forbidden.vue")

const Profile = () => import("./components/Profile.vue")
const ChangePassword = () => import("./components/ChangePassword.vue")

const routes = [
  { path: "/", name: "home",  component: Home, beforeEnter: auth },
  { path: "/home", component: Home, beforeEnter: auth },
  { path: "/login", component: Login },
  { path: "/register", component: Register },
  // lazy-loaded
  { path: "/activities", name: "activities", component: ActivityList, beforeEnter: auth },
  { path: "/activity/new", name: "activityNew", component: ActivityNew, beforeEnter: auth },
  { path: "/activity/:id", name: "activityEdit", component: ActivityEdit, beforeEnter: auth },
  { path: "/activities/daylist", name: "activitiesDayList", component: ActivityDayList, beforeEnter: auth },
  { path: "/activities/activitiesavailable", name: "activitiesAvailable", component: ActivitiesAvailable, beforeEnter: auth },
  { path: "/activity/:id/details", name: "activityDetails", component: ActivityDetails, beforeEnter: auth },
  { path: "/reservations/new", name: "reservationNew", component: ReservationNew, beforeEnter: auth },
  { path: "/reservations/locationsavailable", name: "locationsAvailable", component: LocationsAvailable, beforeEnter: auth },
  { path: "/card", name: "card", component: Card, beforeEnter: auth },
  { path: "/profile", name: "profile", component: Profile, beforeEnter: auth },
  { path: "/changepassword", name: "changePassword", component: ChangePassword, beforeEnter: auth },
  { path: "/forbidden", name: "forbidden", component: Forbidden, beforeEnter: auth },  

  
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;