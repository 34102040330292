<template>
  <nav class="navbar navbar-expand-sm navbar-dark bg-dark">
    <!-- <a href="/" class="navbar-brand">Portal do associado</a> -->
    <router-link to="/home" class="navbar-brand">
      Portal do associado
    </router-link>
    
    <button class="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarsExample03" aria-controls="navbarsExample03" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="navbar-collapse collapse" id="navbarsExample03" style="">
      <ul class="navbar-nav navbar-center mr-auto">
        <li class="nav-item active">
          <a class="nav-link" href="/home">Inicio <span class="sr-only">(current)</span></a>
        </li>
      </ul>
      <ul v-if="currentUser" class="navbar-nav navbar-center mr-auto">
        <li class="nav-item">
          <a href="#" class="navbar-brand d-none d-md-block">{{ currentUser.tradingname }} <small> ( {{ currentUser.companyname }} )</small></a>
        </li>
      </ul>
      <form class="form-inline my-2 my-md-0">
        <a class="nav-link text-light" @click.prevent="logOut">
          <font-awesome-icon icon="sign-out-alt" /> Sair
        </a>
      </form>
    </div>
  </nav>
  <!-- <div v-if="currentUser" class="mx-auto" style="width: 200px;">
    <a href="#" class="navbar-brand">{{ currentUser.tradingname }} <small> ( {{ currentUser.companyname }} )</small></a>
  </div> -->

</template>

<script>
export default {
  props: {
    currentUser: Object
  },
  methods: {
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    }
  }
}
</script>