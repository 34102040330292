import axios from 'axios';

const urls = {
  development: 'http://localhost:8080/',
  production: 'https://nifty-anthem-326801.uc.r.appspot.com/',
};

const API_URL =  urls[process.env.NODE_ENV];
class AuthService {
  login(user) {
    return axios
      .post(API_URL + 'associates/login', {
        cpf: user.username,
        password: user.password
      })
      .then(response => {
        console.log('response => ', response)
        if (response.data.token) {
          localStorage.setItem('user', JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem('user');
  }

  register(user) {
    return axios.post(API_URL + 'signup', {
      username: user.username,
      cpf: user.cpf,
      password: user.password
    });
  }
}

export default new AuthService();
