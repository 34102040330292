<template>
  <div class="container">
    <div class="row">
      <div class="col-sm p-3">
        <router-link to="/setting/list" class="btn btn-primary btn-lg w-100 p-3">
          Minhas atividades
        </router-link>
      </div>
      <div class="col-sm p-3">
        <router-link to="/activities/activitiesavailable" class="btn btn-primary btn-lg w-100 p-3">
          Atividades
        </router-link>
      </div>
      <div class="col-sm p-3">
        <router-link to="/reservations/locationsavailable" class="btn btn-primary btn-lg w-100 p-3">
          Reservas
        </router-link>
      </div>
      <div class="col-sm p-3">
        <router-link to="/card" class="btn btn-primary btn-lg w-100 p-3">
          Cartão
        </router-link>
      </div>
      <div class="col-sm p-3">
        <router-link to="/profile" class="btn btn-primary btn-lg w-100 p-3">
          Meus dados
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Home",
  data() {
    return {
      content: "",
    };
  },
};
</script>
